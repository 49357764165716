import * as React from 'react'
import { useQuery, gql, ApolloError } from '@apollo/client'
import { useFeature } from '@growthbook/growthbook-react'
import { useGrowthBookWrapperContext } from 'context/growthbook-context'
import { SHOPIFY_PRODUCTS_BY_IDS_QUERY } from 'lib/api/products'
import { priceElasticityExperimentName, priceElasticityExperimentControlVariationName } from 'misc-variables'
import { IMedicine } from 'interfaces/medicine'
import * as Sentry from '@sentry/nextjs'
import { IShopifyProduct } from 'interfaces/product'

interface IArgs {
    medicines: IMedicine[]
}

interface IReturn {
    assignedProductsWithLatestShopifyDetails: IShopifyProduct[]
    loading: boolean
    error: ApolloError
}

const useAssignedShopifyProductOfMultipleMedicines = ({ medicines }: IArgs): IReturn => {
    const priceElasticityExperiment = useFeature(priceElasticityExperimentName)
    const assignedPriceVariation = priceElasticityExperiment?.value ?? priceElasticityExperimentControlVariationName

    const { isGrowthbookReady } = useGrowthBookWrapperContext()

    const shopifyProductIdsOfMedicines = medicines.map((product) => {
        let assignedShopifyProduct = product.allAssociatedShopifyProducts.find((p) =>
            p.node.tags.find((tag) => tag.toLowerCase() === assignedPriceVariation.toLowerCase()),
        )?.node

        if (!assignedShopifyProduct) {
            assignedShopifyProduct = product.allAssociatedShopifyProducts[0].node
        }

        return assignedShopifyProduct.id
    })

    const { data, error, loading } = useQuery(gql(SHOPIFY_PRODUCTS_BY_IDS_QUERY), {
        variables: {
            ids: shopifyProductIdsOfMedicines,
        },
        skip: shopifyProductIdsOfMedicines.length === 0 || !isGrowthbookReady,
    })

    React.useEffect(() => {
        if (error) {
            Sentry.captureException(`Error fetching latest shopify product details. Raw: ${JSON.stringify(error)}`)
        }
    }, [error])

    return {
        assignedProductsWithLatestShopifyDetails: data?.nodes,
        loading,
        error,
    }
}

export default useAssignedShopifyProductOfMultipleMedicines
